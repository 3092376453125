.events-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  box-sizing: border-box;
}

.events-container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
}
.event-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  max-width: 500px;
  background-color: #020420;
  padding: 20px;
  margin: 0 auto 20px auto;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  color: white;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-sizing: border-box;
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

/* Image Container */
.event-image-container {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 15px;
  background-color: #333; /* Fallback color */
}

/* Image Styling */
.event-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Event Details */
.event-details {
  flex: 1;
}

.event-card h2 {
  font-size: 24px;
  margin: 0 0 5px;
}

.event-card p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

/* Floating Action Button (FAB) Styling */
.fab {
  position: fixed;
  bottom: 120px;
  /* right: 30px; */
  background-color: #020420;
  color: white;
  border: 1px solid white;
  border-radius: 20px;
  /* width: 60px;
  height: 60px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  max-width: 200px;
}

.fab:hover {
  background-color: #333860;
  transform: scale(1.1);
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  box-sizing: border-box;
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  box-sizing: border-box;
}

/* Modal Title */
.modal-content h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

/* Input Styling */
.modal-input, .modal-textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.modal-textarea {
  height: 100px;
  resize: none;
}

/* Checkbox Styling */
.modal-checkbox {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.modal-checkbox input {
  margin-right: 8px;
}

/* Submit Button */
.modal-submit {
  background-color: #020420;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;
}

.modal-submit:hover {
  background-color: #0056b3;
}

/* Close Button */
.modal-close {
  background: none;
  border: none;
  color: #777;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
}

.modal-close:hover {
  color: #333;
}

.filter-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.filter-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.filter-button.active {
  background-color: #333;
  color: #fff;
}

.filter-button:hover:not(.active) {
  background-color: #e0e0e0;
}
