.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.header h2 {
  font-size: 24px;
  color: #020420;
  margin: 0;
  box-sizing: border-box;
}

.header p {
  font-size: 16px;
  color: #666;
  margin: 0;
  box-sizing: border-box;
}
.balance-section {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0 20px;
  box-sizing: border-box;
}

.balance-info {
  margin-bottom: 15px;
}

.balance-info h3 {
  font-size: 18px;
  color: #020420;
  margin-bottom: 8px;
}

.total-events-count {
  font-size: 36px;
  font-weight: bold;
  color: #020420;
  margin: 0;
}

.change {
  font-size: 14px;
  color: #4caf50;
}

.balance-actions {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.primary-button {
  background-color: #020420;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  width: 40%;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
}

.primary-button:hover {
  background-color: #1a1a4d;
}

.primary-button.create-event {
  background-color: #020420;
}

.primary-button.manage-events {
  background-color: #020420;
}

.stats-graphs-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 20px;
  box-sizing: border-box;
}

.stat-card {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
}

.stat-card h4 {
  font-size: 16px;
  color: #666;
  margin: 0 0 10px;
  box-sizing: border-box;
}

.stat-card p {
  font-size: 24px;
  color: #020420;
  margin: 0;
  box-sizing: border-box;
}

.quick-stats {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin: 0 20px;
  box-sizing: border-box;
}

.quick-stats .stat-card {
  flex: 1;
  min-width: 45%;
  box-sizing: border-box;
}

.transaction-history {
  margin: 0 20px 20px;
  box-sizing: border-box;
}

.transaction-history h4 {
  font-size: 18px;
  color: #020420;
  margin: 0 0 10px;
  box-sizing: border-box;
}

.transaction-item {
  display: flex;
  justify-content: space-between;
  background-color: #f5f7fa;
  padding: 10px 15px;
  border-radius: 8px;
  margin-bottom: 8px;
  box-sizing: border-box;
}

.transaction-item p {
  color: #666;
  font-size: 14px;
  margin: 0;
  box-sizing: border-box;
}

.transaction-item span {
  font-size: 14px;
  color: #999;
  margin: 0;
  box-sizing: border-box;
}

/* Make charts responsive */
.stats-graphs-section .stat-card canvas {
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
}

.important-action {
  margin-top: 15px;
  width: 100%;
  text-align: center;
}

.important-button {
  background-color: #020420;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.important-button:hover {
  background-color: #020420;
}