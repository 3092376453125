/* Overlay and Modal Container */
.edit-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .edit-modal-content {
    background: #ffffff;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    text-align: center;
  }
  
  /* Title */
  .edit-modal-content h2 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  /* Form and Fields */
  .form-group {
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .form-group label {
    font-size: 0.9rem;
    color: #555;
    display: block;
    margin-bottom: 4px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s ease-in-out;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #4a90e2;
    outline: none;
  }
  
  /* Action Buttons */
  .edit-modal-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
  }
  
  .save-button,
  .cancel-button {
    flex: 1;
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    color: #fff;
  }
  
  .save-button {
    background-color: #020420;
    border: none;
  }

  
  .cancel-button {
    border: none;
    background-color: #020420;

  }
  

  