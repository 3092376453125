
  body {
    font-family: Arial, sans-serif;
  }
  
  /* Header Styles */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #d4af37; /* Gold color */
  }
  
  .header-logo {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .header-nav {
    display: flex;
    gap: 20px;
  }
  
  .header-nav a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
  }
  
  .header-icons {
    display: flex;
    gap: 15px;
  }
  
  .icon {
    font-size: 18px;
    color: #333;
    cursor: pointer;
  }
  
  .menu-icon {
    display: none;
    font-size: 24px;
    color: #333;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .header-nav {
      display: none;
    }
    .menu-icon {
      display: block;
    }
  }
  
  /* Main Content */
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    background-color: #f9f9f9;
  }
  
  .welcome-section {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .welcome-section h1 {
    font-size: 36px;
    color: #333;
  }
  
  .welcome-section p {
    font-size: 18px;
    color: #666;
    margin: 10px 0;
  }
  
  .buttons {
    display: flex;
    gap: 15px;
    margin-top: 20px;
  }
  
  .join-btn {
    padding: 10px 20px;
    background-color: #d4af37;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .login-btn {
    padding: 10px 20px;
    background-color: transparent;
    color: #333;
    border: 1px solid #333;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .main-image {
    width: 300px;
    border-radius: 10px;
  }
  
  /* About Us Section */
  .about-us {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 40px 20px;
    background-color: #ffffff;
  }
  
  .about-text {
    max-width: 50%;
  }
  
  .about-text h2 {
    font-size: 28px;
    color: #333;
  }
  
  .about-text p {
    font-size: 16px;
    color: #666;
    margin-top: 10px;
  }
  
  .about-image img {
    width: 300px;
    border-radius: 10px;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .about-us, .main-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .about-text, .welcome-section {
      max-width: 90%;
    }
  
    .about-image img, .main-image {
      width: 80%;
      margin-top: 20px;
    }
  }
  