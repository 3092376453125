.check-in-page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    
    .back-button {
      position: absolute;
      top: 15px;
      left: 15px;
      background-color: transparent;
      color: #020420;
      border: none;
      font-size: 18px;
      cursor: pointer;
      transition: color 0.3s ease;
    }
  
  }

  .check-in-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    h1 {
      font-size: 24px;
      color: #020420;
      margin-bottom: 20px;
    }
  }
  
  
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  label {
    font-size: 16px;
    color: #333;
  }
  
  .event-select {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-top: 8px;
    background-color: #f9f9f9;
  }
  
  .check-in-button {
    background-color: #020420;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    margin-top: 15px;
    transition: background-color 0.3s ease;
  }
  
  .check-in-button:hover {
    background-color: #388e3c;
  }
  
 

  
  .camera-feed {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 20px 0;
  }
  
  .qr-code-scanner {
    text-align: center;
    padding: 20px;
  }
  
  .qr-result {
    margin-top: 20px;
  }


  .qr-result-card {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
  }
  
  .qr-result-card h2 {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    margin-bottom: 16px;
  }
  
  .qr-result-card p {
    margin: 8px 0;
    font-size: 1.1em;
  }
  
  .checked-in-users-list {
    margin-top: 16px;
  }
  
  .checked-in-users-list h3 {
    margin-bottom: 8px;
  }
  
  .checked-in-users-list ul {
    list-style: none;
    padding: 0;
  }
  
  .checked-in-users-list li {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    font-size: 1em;
  }