.event-details-page {
  font-family: Arial, sans-serif;
  color: #333;
  width: 100%;
}


.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: transparent;
  color: white;
  border: none;
  padding: 8px 12px;
  font-size: 24px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.event-hero {
  position: relative;
  height: 400px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}



.event-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay for readability */
}

.event-title {
  position: relative;
  font-size: 2rem;
  z-index: 1; /* Ensures the title is above the overlay */
  padding: 0 20px; /* Padding for better readability */
}


.event-details-card {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  box-sizing: border-box;
}
.event-detail {
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 12px 0;
  border-bottom: 1px solid #e0e0e0;
}

.event-detail:last-child {
  border-bottom: none;
}

.detail-icon {
  color: #020420;
  margin-right: 10px;
  font-size: 22px;
}

.event-description {
  margin-top: 20px;
}

.event-description h3 {
  font-size: 20px;
  color: #020420;
  margin-bottom: 8px;
}

.event-description p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}


.event-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.edit-button, .delete-button {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-button {
  /* background-color: #4CAF50;  */
  color: #ffffff;
}

.edit-button:hover {
  background-color: #020420; /* Darker green on hover */
}



.add-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.add-modal-content {
  background: #ffffff;
  padding: 25px;
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-modal-header {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 10px;
}

.add-modal-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.option-section {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #ddd;
  flex: 1;
}

.option-section h3 {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 10px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
}

.add-modal-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.add-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 15px;
}

.add-modal-submit {
  padding: 10px 20px;
  background-color: #020420;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.add-modal-submit:hover {
  background-color: #020420;
}

.add-modal-close {
  padding: 10px 20px;
  background-color: #020420;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.add-modal-close:hover {
  background-color: #020420;
}

.add-modal-file-input {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}



.attendee-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.attendee-modal-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.attendee-modal-content h3 {
  margin-bottom: 10px;
}

.attendee-modal-content ul {
  list-style-type: none;
  padding: 0;
}

.attendee-modal-content li {
  margin: 5px 0;
}

.close-attendee-modal {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #020420;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
