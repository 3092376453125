/* Error message styling */

h1{
padding-bottom: 20px;
}

/* Success message styling */
.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeIn 1s ease-in-out; /* Fade-in animation */
  color: #28a745; /* Green color for success */
}

/* Green checkmark styling */
.checkmark {
  font-size: 80px;
  color: #28a745; /* Green color */
  margin-bottom: 20px;
  animation: pop 0.6s ease-in-out; /* Pop animation */
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Pop animation for the checkmark */
@keyframes pop {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Verification form styling */
.verification-form {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  align-items: center;
}


  /* Input field styling */
  .input-field {
    width: 90%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }


/* Hover state for button */
.submit-button:hover {
  background-color: #0056b3;
}


.create-account-text {
  margin-top: 15px;
  font-size: 14px;
  color: #666;
  text-align: center;
}


.create-account-link {
  color: #020420;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}

.create-account-link:hover {
  color: #333860;
}

.remember-forgot-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%; /* Ensure it takes the full width of the form */
  padding-bottom: 10px;
}

.remember-me {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.remember-me input {
  margin-right: 5px;
}

.forgot-password-link {
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  flex-grow: 1; /* Pushes the link to the right */
  text-align: right; /* Aligns the link text to the right */
}

.forgot-password-link:hover {
  text-decoration: underline;
}


/* Wrapper for the password input and icon */
.password-input-wrapper {
  display: flex;
  align-items: center;
  width: 90%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Style the input field */
.password-input-field {
  border: none;
  outline: none;
  flex: 1;
  font-size: 16px;
}

/* Style for the toggle icon */
.password-toggle-icon {
  cursor: pointer;
  color: #666; /* Customize icon color */
  margin-left: 8px;
}



/* General styles for the container */


.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  transition: all 0.5s ease-in-out;
}

.spinner.success {
  border-color: #28a745;
  border-top-color: #28a745;
  animation: none; /* Stop the spinning */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner.success::before {
  content: '✔';
  color: #28a745;
  font-size: 2rem;
  position: absolute;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
