.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  min-height: 100vh;
  padding-bottom: 50px; /* Space for bottom nav */
  box-sizing: border-box;
  padding: 10px;
}

.profile-header {
  text-align: center;
  padding: 20px;
  background-color: white;
  width: 100%;
  max-width: 500px;
  border-radius: 12px;
  margin-top: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.profile-avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.profile-header h2 {
  font-size: 24px;
  color: #020420;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.profile-header p {
  font-size: 16px;
  color: #666;
  margin-top: 4px;
  box-sizing: border-box;
}

.edit-button {
  background-color: #020420;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  box-sizing: border-box;
}

.edit-button:hover {
  background-color: #1a1a4d;
}

.profile-options {
  width: 90%;
  max-width: 500px;
  margin-top: 20px;
  box-sizing: border-box;
}

.option-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  box-sizing: border-box;
}

.option-item span {
  font-size: 20px;
  color: #888;
  box-sizing: border-box;
}

.logout-button {
  width: 100%;
  background-color: white;
  color: #020420;
  border: none;
  padding: 15px;
  font-size: 16px;
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.logout-button:hover {
  background-color: #f8f8f8;
}


/* Modal Overlay */
.edit-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  box-sizing: border-box;
}

/* Modal Content */
.edit-modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  text-align: center;
}

/* Form Inputs */
.edit-modal-content input[type="text"],
.edit-modal-content input[type="email"],
.edit-modal-content input[type="password"],
.edit-modal-content input[type="tel"],
.edit-modal-content input[type="datetime-local"],
.edit-modal-content textarea {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.edit-modal-content input:focus,
.edit-modal-content textarea:focus {
  border-color: #020420;
  outline: none;
}

/* Modal Buttons */
.edit-modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.save-button {
  background-color: #020420;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  width: 48%;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #1a1a4d;
}

.cancel-button {
  background-color: #f8f8f8;
  color: #333;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  width: 48%;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background-color: #e0e0e0;
}


/* Password Strength Bar Styling */
.password-strength-bar {
  background-color: #e0e0e0;
  border-radius: 5px;
  height: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  transition: width 0.3s ease;
  position: relative;
}

.strength-meter {
  height: 100%;
  border-radius: 5px;
}

.strength-20 {
  background-color: red;
}

.strength-40 {
  background-color: orange;
}

.strength-60 {
  background-color: yellow;
}

.strength-80 {
  background-color: lightgreen;
}

.strength-100 {
  background-color: green;
}

/* Password Error Styling */
.password-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
